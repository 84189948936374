import React, {useEffect, useState} from 'react'
import axios from 'axios'
import Loader from 'react-js-loader'
import CurrencyFormat from 'react-currency-format'
import { KTSVG } from '../../../_metronic/helpers'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {CSVLink} from 'react-csv'
import {Page, Text, View, Document, StyleSheet, PDFDownloadLink} from '@react-pdf/renderer'
import {Dropdown} from 'react-bootstrap'
import '../myCommitments/filterbutton.css'

const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v'

const getLocalData = () => {
  const data = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  return JSON.parse(data)
}

const OrderDetails = () => {
  const [loading, setLoading] = useState(false)
  const [orderData, setOrderData] = useState([])
  const [title, setTitle] = useState("")
  const [serial, setSerial] = useState([])

  const [dateRange, setDateRange] = useState([null, null])
  const [rangeStartDate, rangeEndDate] = dateRange

  const [isOpen, setIsOpen] = useState(false);
  const [showHeader, setShowHeader] = useState(true);
  const [toggleFilter, setToggleFilter] = useState("NONE");
  const [filterOpen, setFilterOpen] = useState(false);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [searchQuery,setSearchQuery] = useState("");

  const toggleMenu = () => {
    setIsOpen(!isOpen);
    if(isOpen){
      setToggleFilter("NONE");
    }
  };

  const handleButtonClick = () => {
    console.log("floating button clicked");
  };

  const openFilterBox = ()=>{
    setFilterOpen(true);
  }

  const closeFilterBox = ()=>{
    setFilterOpen(false);
  }

  const apiToken = getLocalData().api_token

  const API_URL = process.env.REACT_APP_API_URL
  const ORDERS = `${API_URL}/user/order/details`

  const getOrderData = async () => {
    setLoading(true)
    const config = {
      headers: {Authorization: `Bearer ${apiToken}`},
    }
    await axios
      .get(ORDERS, config)
      .then((response) => {
        setOrderData(response.data.data)
        setLoading(false)
      })
      .catch((error) => {
        console.error(error)
        setLoading(false)
      })
  }

  useEffect(() => {
    getOrderData()
  }, [])

  const startDateObject = new Date(startDate);
  const endDateObject = new Date(endDate);

  const handleSingleSearch = (value)=>{
    setSearchQuery(value)
  }

  const handleFilter = () => {
    if (startDate !== "" && endDate !== "") {
      const filteredItems = orderData.filter(
        (item) =>
          item.model?.toLowerCase().includes(searchQuery.toLowerCase()) &&
          item.upc?.toLowerCase().includes(searchQuery.toLowerCase()) &&
          item.deal?.toLowerCase().includes(searchQuery.toLowerCase()) &&
          item.tracking_id?.toLowerCase().includes(searchQuery.toLowerCase()) &&
          new Date(item.date) >= startDateObject && new Date(item.date) <= endDateObject
      );
      return filteredItems;
    } else {
      const filteredItems = orderData.filter(
        (item) =>
          item.model?.toLowerCase().includes(searchQuery.toLowerCase()) ||
          item.upc?.toLowerCase().includes(searchQuery.toLowerCase()) ||
          item.deal?.toLowerCase().includes(searchQuery.toLowerCase()) ||
          item.tracking_id?.toLowerCase().includes(searchQuery.toLowerCase())
      );
      return filteredItems;
    }
  };

  const cancleSearch = () => {
    setStartDate("");
    setEndDate("");
    setSearchQuery("")
  };

  const Pdfstyles = StyleSheet.create({
    page: {
      flexDirection: 'column',
      padding: 20,
    },
    table: {
      display: 'table',
      width: 'auto',
      borderStyle: 'solid',
      borderWidth: 1,
      borderRightWidth: 0,
      borderBottomWidth: 0,
    },
    tableRow: {
      flexDirection: 'row',
    },
    tableCol: {
      width: '25%',
      borderStyle: 'solid',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
    },
    tableCell: {
      margin: 'auto',
      marginTop: 5,
      marginBottom: 5,
      fontSize: 10,
    },
    header: {
      fontSize: 18,
      marginBottom: 10,
      textAlign: 'center',
    },
  })

  const orderDetailsCsvHeaders = [
    // {label: 'Image', key: 'image'},
    {label: 'Description', key: 'description'},
    {label: 'Model', key: 'model'},
    {label: 'UPC', key: 'upc'},
    {label: 'Tracking Number', key: 'tracking'},
    {label: 'Qty', key: 'qty'},
    {label: 'Price', key: 'price'},
    {label: 'Commission', key: 'commission'},
    {label: 'Total', key: 'total'},
    {label: 'Payment', key: 'payment_status'},
    {label: 'Date', key: 'date'},
  ]

  const orderDetailsCsvData = orderData.map((item) => ({
    // image: item.deal_image,
    description: item.deal,
    model: item.model,
    upc: item.upc,
    tracking: item.tracking_id,
    qty: item.qty,
    price: '$' + item.price,
    commission: '$' + item.commissiom,
    total: '$' + item.total,
    payment_status: item.payment_status == 1 ? 'PAID' : 'PENDING',
    date: new Date(item.date).toLocaleDateString(),
  }))

  const OrderDetailsPdfDocument = ({data}) => (
    <Document>
      <Page size='A4' orientation='landscape' style={Pdfstyles.page}>
        {/* <Text style={Pdfstyles.header}>Statement Data</Text> */}
        <View style={Pdfstyles.table}>
          {/* Table Header */}
          <View style={Pdfstyles.tableRow}>
            {orderDetailsCsvHeaders.map((header, index) => (
              <View style={[Pdfstyles.tableCol, {backgroundColor: 'skyblue'}]} key={index}>
                <Text style={Pdfstyles.tableCell}>{header.label}</Text>
              </View>
            ))}
          </View>
          {/* Table Content */}
          {data.map((item, index) => (
            <View style={Pdfstyles.tableRow} key={index}>
              {orderDetailsCsvHeaders.map((header, headerIndex) => (
                <View style={Pdfstyles.tableCol} key={headerIndex}>
                  <Text style={Pdfstyles.tableCell}>{item[header.key]}</Text>
                </View>
              ))}
            </View>
          ))}
        </View>
      </Page>
    </Document>
  )

  const currentDate = new Date()
  const formattedDate = currentDate.toLocaleDateString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  })

  const priceBody = (item) => {
    return(
      <CurrencyFormat
          decimalScale={2}
          value={parseFloat(item.price).toFixed(2)}
          displayType={'text'}
          thousandSeparator={true}
          prefix={'$'}
        />
    )
  };
  const commissionBody = (item) => {
    return(
      <CurrencyFormat
        decimalScale={2}
        value={parseFloat(item.commissiom).toFixed(2)}
        displayType={'text'}
        thousandSeparator={true}
        prefix={'$'}
      />
    )
  };
  const totalBody = (item) => {
    return(
      <CurrencyFormat
          decimalScale={2}
          value={parseFloat(item.total).toFixed(2)}
          displayType={'text'}
          thousandSeparator={true}
          prefix={'$'}
        />
    )
  };

  const srnoBody = (item) =>{
    return (
      <span>{orderData.indexOf(item) + 1}</span>
    );
  }

  const actionBody = (item) =>{
    return(
      <>
        <button
        className='btn btn-light btn-sm'
        data-bs-toggle='modal'
        data-bs-target='#kt_modal_1'
        onClick={() => { 
          setTitle(item?.deal)
          setSerial(item?.serial_no)
        }}
      >
        View Serial
      </button>
      </>
    )
  }

  const trackingBody = (item) =>{
    return(
      <>
      <div className='d-flex align-items-center'>
      <h6>{item.tracking_id}</h6>
        <button
        className='btn btn-sm'
        data-bs-toggle='modal'
        data-bs-target='#kt_modal_1'
        onClick={() => { 
          setTitle(item?.deal)
          setSerial(item?.serial_no)
        }}
      >
        <i className="fa-solid fa-circle-info fa-2x mb-2"></i>
      </button>
      </div>
      </>
    )
  }

  const imageBody = (item)=>{
    return(
      <>
      <img src={item.deal_image} alt='' style={{ width: "60px" }} />
      </>
    )
  }

  const dealcolumnStyle = {
    paddingLeft:10,
    paddingRight:10,
    paddingTop:10,
    paddingBottom:10,
    marginLeft:10,
    marginRight:10,
    width:'300px'
  }
  const columnStyle = {
    paddingLeft:10,
    paddingRight:10,
    paddingTop:10,
    paddingBottom:10,
    marginLeft:10,
    marginRight:10,
    width:'150px'
  }
  const smallcolumnStyle = {
    paddingLeft:10,
    paddingRight:10,
    paddingTop:10,
    paddingBottom:10,
    marginLeft:10,
    marginRight:10,
    width:'100px'
  }

  const truncateString = (str) =>{
    const maxLength = 40;
    if (str.length > maxLength) {
      return str.substring(0, maxLength) + '...';
    } else {
      return str;
    }
  }

  const tableHeader = () =>{
    if(toggleFilter == "SEARCH"){
      return(
        <div className="pb-2 pt-5">
        <div className='d-flex mb-5'>
            <div className='mx-2 search-filter-wrap'>
              <input
                type='text'
                className='form-control'
                placeholder='Search here...'
                value={searchQuery}
                onChange={(e) => handleSingleSearch(e.target.value)}
              />
            </div>

            <div className='mx-2'>
            <button
              onClick={() => cancleSearch()}
              className='btn btn-info'
            >
              <i className="fa-solid fa-xmark fs-3"></i>
            </button>
          </div>
        </div>
        </div>
  
      )
    }
    if(toggleFilter == "DATE"){
      return(
        <div className="pb-2 pt-5">
        <div className='d-flex mb-5'>
        <div className='mx-2  calendar-filter-wrap'>
             <label className='form-label position-absolute text-gray-600' style={{ marginTop: "-18px" }}>Start Date</label>
            <input
              type='date'
              className='form-control'
              placeholder='DD-MM-YYYY'
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </div>
          <div className='mx-2 calendar-filter-wrap'>
             <label className='form-label position-absolute text-gray-600' style={{ marginTop: "-18px" }}>End Date</label>
            <input
              type='date'
              className='form-control'
              placeholder='DD-MM-YYYY'
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
            
          </div>
          <div className='mx-2'>
            <button
              onClick={() => cancleSearch()}
              className='btn btn-infor'
              disabled={startDate === "" && endDate === ""}
            >
              <i className="fa-solid fa-xmark fs-3"></i>
            </button>
          </div>
        </div>
        </div>
      )
    }
    if(toggleFilter == "NONE"){
      return false;
    }
  }

  const dealBody = (item) =>{
    return(
      <div>
    <OverlayTrigger
      key="top"
      placement="top"
      overlay={<Tooltip id={`tooltip-top`} style={{ maxWidth: '400px' }}>{item.deal}</Tooltip>}
    >
      <span className='fs-5 fw-bold'>{item.deal}</span>
    </OverlayTrigger>
    
    </div>
    )
  }

  const paymentStatusBody = (item)=>{
    if(item.payment_status == 1){
    return(
      <span className='badge badge-success'>PAID</span>
    )
  }else{
    return(
      <span className='badge badge-warning'>PENDING</span>
    )
  }
  }

  const getSerailCondition = (item) =>{
    if(item == 1){
      return(<h6 className="badge badge-success">Good</h6>)
    };
    if(item == 2){
      return(<h6 className="badge badge-danger">Damaged</h6>)
    }
    if(item == 3){
      return(<h6 className="badge badge-warning">Open Box</h6>)
    }
  }

  const filterButton = ()=>{
    return(
      <div className="floating-button-container">
        {isOpen && (
          <div className={`menu-buttons ${isOpen ? 'show' : ''}`}>
            {/* <button className="sub-button" onClick={()=> setToggleFilter("EXPORT")}><i className="fas fa-download"></i></button> */}
            <button className="sub-button" onClick={()=> setToggleFilter("DATE")}><i className="fas fa-calendar-alt"></i></button>
            <button className="sub-button" onClick={()=> setToggleFilter("SEARCH")}><i className="fas fa-search"></i></button>
          </div>
        )}
        <button
          className="main-button"
          onClick={toggleMenu}
        >
          {isOpen ?
          <i className="fas fa-close"></i>
          :
          <i className="fas fa-sliders-h"></i>
          }
          
          
        </button>
      </div>
    )
  }

  return (
    <div>
      <div className='d-flex align-items-center justify-content-between'>
      <h1>Order Details</h1>
      <Dropdown>
        <Dropdown.Toggle variant='success' id='dropdown-basic' className='btn btn-primary btn-sm mb-2'>
          Export
        </Dropdown.Toggle>

        <Dropdown.Menu style={{width:'225px'}}>
          <Dropdown.Item onClick={(e)=>{
            e.preventDefault();
            e.stopPropagation();
          }}>
            <DatePicker
              selectsRange={true}
              startDate={rangeStartDate}
              endDate={rangeEndDate}
              onChange={(update) => {
                setDateRange(update)
              }}
              isClearable={true}
              className='form-control form-control-sm'
              placeholderText='Select Date Range'
            />
          </Dropdown.Item>

          <Dropdown.Item as="div" >
          <div className="d-flex justify-content-between align-items-center">
          <button className='btn btn-sm btn-primary text-dark'>
            <CSVLink
              className='downloadbtn'
              filename={`order_details_${formattedDate}.csv`}
              headers={orderDetailsCsvHeaders}
              data={orderDetailsCsvData}
              style={{color: '#fff'}}
            >
              Export CSV
            </CSVLink>
          </button>
          <PDFDownloadLink
            document={<OrderDetailsPdfDocument data={orderDetailsCsvData} />}
            fileName={`order_details_${formattedDate}.pdf`}
            className='btn btn-sm btn-danger ms-2'
          >
            {({blob, url, loading, error}) =>
              loading ? 'Export PDF' : 'Export PDF'
            }
          </PDFDownloadLink>
          </div>
          </Dropdown.Item>

          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div className='card shadow'>
        <div className='card-body p-0'>
        <div className="prime-table">
        <DataTable value={handleFilter()} removableSort size="large" 
        paginator rows={10} rowsPerPageOptions={[5, 10, 25, 50]}
                tableStyle={{ minWidth: '60rem' ,alignItems:'center',marginLeft:'auto', fontSize:15}}
                scrollable scrollHeight="90vh" style={{ minWidth: '50rem' }}
                header={isOpen ? tableHeader : ''}
                >

          {/* <Column body={srnoBody} header="#" style={columnStyle}></Column>       */}
          <Column body={imageBody} header="Image"  style={smallcolumnStyle}></Column>  
          <Column body={dealBody} header="Item Description"  style={dealcolumnStyle}></Column>  
          <Column field="model" header="Model" style={columnStyle}></Column>  
          <Column field="upc" header="UPC" style={columnStyle}></Column>   
          {/* <Column field="order_no" header="Order ID" sortable style={columnStyle}></Column>  */}
          <Column field={trackingBody} header="Tracking No." sortable style={columnStyle}></Column>  
          <Column field="qty" header="Qty" style={smallcolumnStyle}></Column>      
          <Column body={priceBody} header="Price" sortable style={smallcolumnStyle}></Column>      
          <Column body={commissionBody} header="Commision" sortable style={smallcolumnStyle}></Column>      
          <Column body={totalBody} header="Total" sortable style={smallcolumnStyle}></Column>      
          {/* <Column body={actionBody} header="Serial" sortable style={columnStyle}></Column>       */} 
          <Column field="date" header="Created Date" sortable style={columnStyle}></Column>      

        </DataTable>
        </div>
        </div>

        <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
          <div className='modal-dialog'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title'>{title}</h5>
                <div
                  className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                  data-bs-dismiss='modal'
                  aria-label='Close'
                >
                  <KTSVG
                    path='/media/icons/duotune/arrows/arr061.svg'
                    className='svg-icon svg-icon-2x'
                  />
                </div>
              </div>
              <div className='modal-body'>
                <div className='row'>
                  <div className='col-md-10'>
                    <label htmlFor="search"><h4><img src="https://cdn2.iconfinder.com/data/icons/cyber-monday-38/512/13_Product_barcode_upc_serial_number-512.png" alt="" width={'40px'} height={'45px'} /> Search for a Serial</h4></label>
                    <input type="search" name="search" id="search" className='form-control' placeholder='Search for a Serial' />
                  </div>
                  <div className='col-md-11'>
                    <br />
                    <ul className="list-group">
                      {serial.length > 0 ? 
                      serial.filter(myitem => myitem.serial_no !== null)
                      .map((item,index) =>(
                          <li className="list-group-item" key={index}>
                       <div className="row">
                         <div className="col-8">
                         <h6>{item?.serial_no}</h6>
                         </div>
                         <div className="col-4">
                         {getSerailCondition(item.serial_condition)}
                         </div>
                       </div>
                       </li>
                        )): (<p className='text-center'>There is no serial</p>)}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{zIndex:999}}>
      {filterButton()}
      </div>
    </div>
  )
}

export default OrderDetails
