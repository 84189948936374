import {useState,useEffect} from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import CurrencyFormat from "react-currency-format";
import { FilterMatchMode, FilterOperator } from 'primereact/api';

const StatementList = ({statementData,OpenViewStatementModal,OpenStatementReceiptModal}) => {

    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };
 
    const renderHeader = () => {
        return (
            <div className="d-flex justify-content-end">
                <input value={globalFilterValue} className='form-control form-control-sm' style={{width:'20%'}} onChange={onGlobalFilterChange} placeholder="Search" />
            </div>
        );
    };

    const ItemsBody = (val) =>{
        if(val.source_type == 1){
            return(
            <button onClick={() => OpenViewStatementModal(val.items)} className='btn btn-sm btn-light'>Details</button>
            )
        }else{
            <span>---</span>
        }
    }
    const AmountBody = (val)=>{
        if(val.transaction_type == 'Credit'){
            return(
                <td className='text-success'>
                    <CurrencyFormat
                    decimalScale={2}
                    value={parseFloat(val.total).toFixed(2)}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={'$'}
                    />
                    {val.payment_status == 1 ? (
                    <span className='badge badge-primary fs-9'>PAID</span>
                    ) : (
                    <></>
                    )}
                </td>
            )
        }else{
            return(
                <td className='text-danger'>
                    <CurrencyFormat
                    decimalScale={2}
                    value={parseFloat(val.total).toFixed(2)}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={'$'}
                    />
                </td>
            )
        }
    }

    const ReceiptBody = (val)=>{
        return(
            <button type='button' onClick={() => OpenStatementReceiptModal(val.statement_no,val.id,val.user_no)} class='btn btn-light btn-sm'>INVOICE</button>
        )
    }

    const header = renderHeader();
    
  return (
    <DataTable value={statementData} paginator rows={20} rowsPerPageOptions={[10, 20, 50, 100]} tableStyle={{ minWidth: '50rem' }} size='large'
    globalFilterFields={['statement_no', 'tracking_no', 'description']} dataKey="id" filters={filters} header={header} stripedRows >
        <Column field="statement_no" header="Stmt" style={{ width: '10%' }}></Column>
        <Column field="tracking_no" header="Tracking" style={{ width: '10%' }}></Column>
        <Column field="description" header="Description" style={{ width: '25%' }}></Column>
        <Column body={ItemsBody} header="Items" style={{ width: '10%' }}></Column>
        <Column body={AmountBody} header="Amount" style={{ width: '15%' }}></Column>
        <Column field="created_at" header="Scanned On" style={{ width: '10%' }} ></Column>
        <Column field="due_date" header="DueDate" style={{ width: '10%' }}></Column>
        <Column body={ReceiptBody} header="Receipt" style={{ width: '10%' }}></Column>
    </DataTable>
  )
}

export default StatementList