/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { Modal } from 'react-bootstrap';
import { KTIcon } from '../../../../../../_metronic/helpers';
import axios from 'axios';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { showToastMessageSuccess, showToastMessageError } from '../../../../../modules/Toast-Message/TosatMessage';

const modalsRoot = document.getElementById('root-modals') || document.body;

const WithdrawDetail = ({ show, handleClose, withdrawId }) => {
    
    const [errorMsg, setErrorMsg] = useState("");
    const [fetchCount,setFetchCount] = useState(true);
    const [withdrawStatements,setWithdrawStatements] = useState([]);
    const [loading,setLoading] = useState(true);

    const API_URL = process.env.REACT_APP_API_URL;
    const WITHDRAW_DETAIL_URL = `${API_URL}/user/withdraw-detail`;

    const getWithdrawDetail = async () => {
            await axios.post(WITHDRAW_DETAIL_URL, {
                withdraw_id: withdrawId,
            }).then((response) => {
                    setWithdrawStatements(response.data.data);
                    console.log(response.data.data);
                    setLoading(false);
                    
            }).catch((error) => {
                console.error(error);
            });
    };

    const setModalClose = ()=>{
        handleClose();
        setWithdrawStatements([]);
        setLoading(true);
    }

    const getDueDate = (date) =>{
        const mydate = new Date(date);
        const dueDate = addDays(mydate, 15);
        return formatDate(dueDate);
    }

    const formatDate = (dateString) => {
        const date = new Date(dateString); // Convert the ISO string to a Date object
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const year = date.getFullYear();
        return `${month}-${day}-${year}`;
      };

    const addDays = (date, days) => {
        const result = new Date(date); 
        result.setDate(result.getDate() + days);
        return result;
      };
 
    useEffect(() => {
        if (show && withdrawId) {
            getWithdrawDetail();
        }
        setErrorMsg("");
    }, [show, withdrawId]);

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered modal-lg'
            show={show}
            onHide={setModalClose}
            backdrop={true}
        >
            <ToastContainer />
            <div className='modal-header pb-0'>
                <h2>Statements on Withdraw</h2>
                <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={setModalClose}>
                    <KTIcon className='fs-1' iconName='cross' />
                </div>
            </div>

            <div className='card'>
                <div className='card-body d-flex pt-0'>
                    {!loading ? (
                        <>
                        <div className="table-responsive">
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>Stmt</th>
                                        <th>Tracking</th>
                                        <th>Amount</th>
                                        <th>Due Date</th>
                                        <th>Scanned On</th>
                                        <th>Paid On</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {withdrawStatements.map((val,index)=>(
                                    <tr>
                                        <td>{val.statement_no}</td>
                                        <td>{val.tracking_no}</td>
                                        <td>${val.stmt_price}</td>
                                        <td>{getDueDate(val.created_at)}</td>
                                        <td>{formatDate(val.created_at)}</td>
                                        <td>{formatDate(val.payment_date)}</td>
                                        <td><span className='badge badge-success'>PAID</span></td>
                                    </tr>
                                    ))
                                }
                                
                                </tbody>
                            </table>
                        </div>
                        </>
                    ) : (
                        <div className='text-center'>
                            <span>Loading....</span>
                        </div>
                    )}
                        
                    
                </div>
            </div>
        </Modal>,
        modalsRoot
    );
}

export { WithdrawDetail };
